import { errorHandler } from "../utils/error";
import { useMutation } from "react-query";
import api from "./axios";
import { APIResponse } from "./_types";
import { toast } from "react-hot-toast";

////////////////////////////////////////////////////////
//////////////////---INTERFACES---/////////////////////
///////////////////////////////////////////////////////

export interface IAuthorizeLocation {
  token: string;
}

////////////////////////////////////////////////////////
////////////////////---API CALLS---/////////////////////
////////////////////////////////////////////////////////

export const authorizeLocation = async (payload: IAuthorizeLocation) => {
  const { data } = await api.post<APIResponse>("/auth/location", payload);
  return data;
};

////////////////////////////////////////////////////////
//////////////////////---HOOKS---///////////////////////
////////////////////////////////////////////////////////

export const useAuthorizeLocation = (handleSuccess: () => void) => {
  return useMutation(authorizeLocation, {
    onSuccess: (response) => {
      toast.success(response.message);
      const data = response.data;
      if (data.token) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("location", JSON.stringify(data));
      }
      handleSuccess();
    },
    onError: (error) => {
      errorHandler(error);
    },
  });
};
