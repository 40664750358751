import { useQueryClient } from "react-query";

export enum QUERY_KEY {
  QUERY_ACCOUNT_STATUS = "QUERY_ACCOUNT_STATUS",
  QUERY_INVITES = "QUERY_INVITES",
  QUERY_TRANSACTIONS = "QUERY_TRANSACTIONS",
  QUERY_BUSINESS_LOCATIONS = "QUERY_BUSINESS_LOCATIONS",
  QUERY_LOCATION_ACCOUNT = "QUERY_LOCATION_ACCOUNT",
}

export const STALE_TIME = 1000 * 60 * 2; // 2 minutes

export const useReset = () => {
  const queryClient = useQueryClient();
  const reset = () => {
    Object.values(QUERY_KEY).forEach((qk) => {
      queryClient.resetQueries(qk);
      queryClient.invalidateQueries(qk);
    });
  };

  return { reset };
};
