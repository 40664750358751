import "twin.macro";
import { motion } from "framer-motion";
import { ReactNode, useEffect } from "react";
import { ReactComponent as CancelIcon } from "../assets/svgs/cancel.svg";

type Props = {
  title?: string;
  children: ReactNode;
  onClose?: () => void;
  isOpen?: boolean;
};

export const Modal = ({ children, title, onClose, isOpen }: Props) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);
  return (
    <motion.div
      onClick={onClose}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: "easeOut" }}
      exit={{ opacity: 0 }}
      tw='fixed inset-0 w-full h-full bg-black bg-opacity-40 flex items-center justify-center z-20'
    >
      <motion.div
        onClick={(e) => e.stopPropagation()}
        initial={{ y: "-60px" }}
        animate={{ y: 0 }}
        transition={{ ease: "easeOut" }}
        exit={{ y: "60px" }}
        tw='bg-white px-32 py-24 rounded-xl md:min-w-[496px] mx-24 md:mx-0'
      >
        <div tw='flex items-center justify-between'>
          {title && (
            <h1 tw='text-slate/10 tracking-tight md:text-d-xs text-lg font-semibold'>
              {title}
            </h1>
          )}
          {onClose && (
            <span tw='block' onClick={onClose}>
              <CancelIcon tw='cursor-pointer' />
            </span>
          )}
        </div>
        <div tw=' max-w-[335px] mx-auto text-center'>{children}</div>
      </motion.div>
    </motion.div>
  );
};
