/* eslint-disable no-param-reassign */
import { generateAuthenticationToken } from "../utils/utils";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

const api: AxiosInstance = axios.create({
  // @ts-ignore
  baseURL: process.env.REACT_APP_BASE_URL as string,
});

// Add request interceptor
api.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    let token = localStorage.getItem("token") ?? null;

    if (!token && typeof window !== "undefined") {
      token = localStorage.getItem("token") ?? null;
      if (token) {
        localStorage.setItem("token", token);
      }
    }

    if (!token && !config!.headers!.Authorization) {
      config!.headers!.Authorization = `Bearer ${generateAuthenticationToken()}`;
    }

    if (token) {
      config!.headers!.Authorization = `Bearer ${token}`;
    }

    config!.headers!["Content-Type"] = "application/json";
    config!.headers!.Accept = "application/json";

    return config;
  },
  (error: any) => {
    // @ts-ignore
    Promise.reject(error);
  }
);

export default api;
