import tw, { styled } from "twin.macro";
import { Modal } from ".";
import { IBusinessLocation } from "../api/business_location";
import { useStore } from "@nanostores/react";
import { location, setLocation } from "../stores/location";

type Props = {
  locations: IBusinessLocation[] | undefined;
  onClose: () => void;
};

const Button = styled.button<{ $active: boolean }>`
  ${tw`text-white px-12 py-8 rounded-full`}
  ${({ $active }) => ($active ? tw`bg-slate/05` : tw`bg-slate/10`)}
`;

export const SwitchLocationModal = ({ locations, onClose }: Props) => {
  const locationData = useStore(location);
  return (
    <Modal onClose={onClose} title='Switch Location'>
      <div tw='py-32 space-y-16'>
        {locations?.map((loc) => (
          <div
            key={loc.uuid}
            tw='bg-slate/02 flex justify-between py-20 px-16 rounded-lg'
          >
            <p>{loc.name}</p>
            <Button
              onClick={() => {
                setLocation(loc);
                window.location.reload();
              }}
              disabled={loc.uuid === locationData?.uuid}
              $active={loc.uuid === locationData?.uuid}
            >
              {loc.uuid === locationData?.uuid ? `Current` : `Switch`}
            </Button>
          </div>
        ))}
      </div>
    </Modal>
  );
};
