import tw, { styled } from "twin.macro";
import { SwitchingText } from "../components";
import { ReactFCWithChildren } from "../../types/react";
import Illustration from "../assets/imgs/illustration.png";
import { ReactComponent as GrocelLogo } from "../assets/svgs/logo.svg";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const BackgroundContainer = styled.div`
  ${tw`bg-blue/08 w-full h-full fixed inset-0`}
  background-image: url('/bg.svg');
  background-size: 120% 100%;
`;

export const AuthLayout: ReactFCWithChildren = ({ children }) => {
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const locationT = localStorage.getItem("@session_location");

  useEffect(() => {
    if (token && locationT) {
      navigate("/");
    }
  }, [locationT, token, navigate]);
  return (
    <BackgroundContainer>
      <div tw='lg:w-1/2 w-full lg:inline-block hidden relative'>
        <div tw='fixed top-90 left-50 w-1/2'>
          <img src={Illustration} alt='Illustration' tw='w-[60%]' />
          <div tw='mt-60'>
            <SwitchingText />
            <div tw='text-d-2xl text-white max-w-md font-medium tracking-tight'>
              Management
            </div>
          </div>
        </div>
      </div>

      <div tw='bg-white h-full lg:w-1/2 w-full inline-block'>
        <div tw='max-w-[372px] mx-auto py-80 overflow-scroll'>
          <div tw='pb-60'>
            <GrocelLogo tw='text-blue/07' />
          </div>
          {children}
        </div>
      </div>
    </BackgroundContainer>
  );
};
