import "twin.macro";

export const TransactionLoader = () => {
  return (
    <>
      {[1, 2, 3, 4, 5].map((v) => (
        <div key={v} tw='flex animate-pulse justify-between py-16 space-y-24'>
          <div tw='flex'>
            <div tw='pr-16'>
              <div tw='w-40 h-40 rounded-full  bg-slate/03' />
            </div>
            <div>
              <p tw='text-md font-semibold  bg-slate/03 py-14 w-400 rounded-lg'></p>
              <p tw='text-sm bg-slate/03 font-medium py-12 w-200 mt-10 rounded-lg'></p>
            </div>
          </div>
          <p tw='font-medium text-slate/06 bg-slate/03 rounded-lg h-40 w-200'></p>
        </div>
      ))}
    </>
  );
};
