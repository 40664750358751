import { DashboardLayout } from "../../layout/DashboardLayout";
import "twin.macro";
import { Transactions } from "./Transactions";

export const Dashboard = () => {
  return (
    <DashboardLayout>
      <Transactions />
    </DashboardLayout>
  );
};
