import { DetailedHTMLProps, InputHTMLAttributes, useState } from "react";
import { ReactComponent as EyeIcon } from "../assets/svgs/eye.svg";
import { ReactComponent as EyeSlashIcon } from "../assets/svgs/eye-slash.svg";
import { ReactComponent as FlagIcon } from "../assets/svgs/flag.svg";
import { ReactComponent as ArrowDownIcon } from "../assets/svgs/arrow-down.svg";
import tw, { styled } from "twin.macro";

type Props = {
  isPassword?: boolean;
  errorText?: string;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const StyledInput = styled.input<Props>(({ type, errorText }) => [
  tw`transition-colors bg-slate/02 text-sm px-16 rounded-lg py-21  focus-visible:(outline-none) border border-transparent placeholder:text-slate/05 focus:border-blue/07`,
  type === "tel" && tw`pl-112`,
  errorText && tw`border-rose/07 text-slate/10 focus:border-rose/07`,
]);

export const Input = (props: Props) => {
  const {
    type,
    placeholder,
    name,
    id,
    isPassword,
    errorText,
    required,
    onChange,
    value,
  } = props;
  const [inputType, setInputType] = useState(type);
  return (
    <div tw='flex flex-col relative'>
      <label
        tw='text-slate/07 tracking-tight text-sm font-medium pb-8 text-left capitalize'
        htmlFor={id}
      >
        {name}
      </label>
      <StyledInput
        errorText={errorText}
        id={id}
        type={inputType}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        value={value}
      />
      {isPassword && (
        <button
          type='button'
          onClick={() => {
            setInputType((prev) => {
              if (prev === "password") {
                return "text";
              }
              return "password";
            });
          }}
        >
          {inputType === "password" ? (
            <EyeIcon tw='absolute top-50 z-10 right-16 cursor-pointer' />
          ) : (
            <EyeSlashIcon tw='absolute top-50 z-10 right-16 cursor-pointer' />
          )}
        </button>
      )}
      {type === "password" && (
        <EyeIcon tw='absolute top-50 right-16 cursor-pointer' />
      )}
      {type === "tel" && (
        <div tw='flex space-x-4 items-center bg-white p-8 rounded-md absolute top-42 left-12'>
          <FlagIcon />
          <p tw='text-sm font-medium'>+234</p>
          <ArrowDownIcon />
        </div>
      )}

      {errorText && (
        <p tw='text-rose/07 pt-4'>
          <span>**</span> {errorText}
        </p>
      )}
    </div>
  );
};
