import "twin.macro";
import { io } from "socket.io-client";
import debounce from "lodash.debounce";
import { useQueryClient } from "react-query";
import { Input, TransactionLoader } from "../../components";
import {
  ITransactionParams,
  useFetchAccountDetails,
  useFetchTransactions,
} from "../../api/transactions";
import dayjs from "dayjs";
import { formatMoney } from "../../utils/utils";
import { useEffect, useMemo, useState } from "react";
import { QUERY_KEY } from "../../api/_keys";

export const Transactions = () => {
  const queryClient = useQueryClient();
  const socket = io(process.env.REACT_APP_BASE_URL || "");
  const lT = localStorage.getItem("location");
  const location = lT ? JSON.parse(lT) : "";
  const [transactionParams, setTransactionParams] =
    useState<ITransactionParams>({
      type: "credit",
      perPage: 30,
      page: 1,
    });

  const { data: accountDetails } = useFetchAccountDetails();

  const { data, isLoading } = useFetchTransactions(transactionParams);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== "") {
      setTransactionParams({
        ...transactionParams,
        search: e.target.value,
      });
    } else {
      setTransactionParams({
        type: "credit",
        perPage: 30,
        page: 1,
      });
    }
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  socket.on("transactions_update", (location_id) => {
    if (location?.location_id === location_id) {
      queryClient.invalidateQueries(QUERY_KEY.QUERY_TRANSACTIONS);
    }
  });

  useEffect(() => {
    setTransactionParams({
      type: "credit",
      perPage: 30,

      page: 1,
    });
  }, []);
  return (
    <>
      <div>
        <h1 tw='text-sm font-medium text-slate/06'>{accountDetails?.bank}</h1>
        <h2 tw='text-d-md font-semibold pb-24'>
          {accountDetails?.account_number}
        </h2>
        <h3 tw='text-sm font-bold'>{accountDetails?.account_name}</h3>
      </div>
      <div tw='pt-56 '>
        <div tw='w-1/4'>
          <Input
            type='text'
            onChange={debouncedResults}
            placeholder='Search Transfers'
          />
        </div>

        <div tw='pt-28 pb-44'>
          {isLoading ? (
            <TransactionLoader />
          ) : (
            data?.map((txn) => (
              <div
                key={txn.session_id}
                tw='flex justify-between border-b last:border-none py-16 border-slate/03'
              >
                <div tw='flex'>
                  <div tw='pr-16'>
                    <img src={txn.logo} alt='Bank logo' tw='w-40 h-40' />
                  </div>
                  <div>
                    <p tw='text-md font-semibold text-slate/09'>
                      {formatMoney(txn.amount)} | {txn.from}
                    </p>
                    <p tw='text-sm text-slate/07 font-medium'>
                      {txn.description}
                    </p>
                  </div>
                </div>
                <p tw='font-medium text-slate/06'>
                  {dayjs(txn.date).format("hh:mm a | DD MMM, YYYY")}
                </p>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};
