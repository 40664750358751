import gsap from "gsap";
import { SplitText } from "gsap/SplitText";
import { useCallback, useEffect, useState } from "react";
import "twin.macro";
import tw, { styled } from "twin.macro";

gsap.registerPlugin(SplitText);

const Container = styled.div<{ $height: string }>`
  ${tw`overflow-hidden`}
  height: ${({ $height }) => $height};
`;

const Text = tw.h1`text-d-2xl font-medium text-white tracking-tight`;

const texts = ["Sales", "Inventory"];

export const SwitchingText = () => {
  const [textHeight, setTextHeight] = useState(90);

  const animateText = useCallback(() => {
    const options = {
      slides: document.querySelectorAll(".text"),
      container: document.querySelector(".container"),
      duration: 0.3,
      lineHeight: textHeight,
    };

    const tl = gsap.timeline({
      repeat: -1,
      paused: true,
    });
    options.slides.forEach(function (slide, i) {
      let label = "slide" + i;
      tl.add(label);

      tl.to(
        options.container,
        {
          duration: options.duration,
          y: i * -1 * options.lineHeight,
        },
        label
      );
      const letters = new SplitText(slide, { type: "chars" }).chars;
      tl.from(
        letters,
        {
          duration: options.duration,
          y: 50,
          stagger: options.duration / 10,
        },
        label
      );

      tl.to({}, { duration: 1 });
    });

    tl.play();
  }, [textHeight]);

  useEffect(() => {
    const elementHeight = document.querySelector(".text");
    setTextHeight(elementHeight?.clientHeight || 90);
    animateText();
  }, [animateText]);
  return (
    <Container $height={`${textHeight}px`}>
      <div className='container'>
        {texts.map((text) => (
          <Text key={text} className='text'>
            {text}
          </Text>
        ))}
      </div>
    </Container>
  );
};
