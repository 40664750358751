import { IBusinessLocation } from "../api/business_location";
import { atom } from "nanostores";

const LOCATION = "@session_location";

export const location = atom<IBusinessLocation | null>(null);

const currentLocation = localStorage.getItem(LOCATION);

if (currentLocation) {
  location.set(JSON.parse(currentLocation));
}

export function setLocation(locationData: IBusinessLocation) {
  localStorage.setItem(LOCATION, JSON.stringify(locationData));
  location.set(locationData);
}
