import { motion, useAnimationControls } from "framer-motion";
import { ReactFCWithChildren } from "../../types/react";
import tw, { css, styled } from "twin.macro";

type ButtonElement = HTMLButtonElement | HTMLAnchorElement;
type HTMLProps = React.HTMLAttributes<ButtonElement>;

type StyleProps = {
  buttonColor?: keyof typeof ButtonColors;
  isDisabled?: boolean;
};
type Props = {
  children: React.ReactNode;
} & HTMLProps &
  StyleProps;

const ButtonColors = {
  blue: tw`bg-blue/07 text-white`,
  grey: tw`bg-slate/02/Alt text-slate/09`,
} as const;

const StyledButton = styled.button<StyleProps>(
  ({ buttonColor = "blue", isDisabled }) => [
    tw`w-full h-56 rounded-lg text-sm font-semibold flex items-center justify-center transition-colors`,

    css`
      ${ButtonColors[buttonColor]}
    `,
    isDisabled && tw`pointer-events-none bg-blue/06 `,
  ]
);

const AnimatedButton = motion(StyledButton);

export const Button: ReactFCWithChildren = ({ children, ...props }: Props) => {
  const controls = useAnimationControls();
  return (
    <AnimatedButton
      {...props}
      whileHover={{ opacity: 0.9 }}
      whileTap={{ scale: 0.98 }}
      transition={{ ease: "easeOut" }}
      animate={controls}
    >
      {children}
    </AnimatedButton>
  );
};
