import { toast } from "react-hot-toast";

export const errorHandler = (error: any) => {
  if (!error.response) {
    return;
  }

  const { data } = error.response;
  if (data?.data?.errors) {
    return data.data.errors[0]
      ? toast.error(data?.data?.errors[0], { position: "top-right" })
      : null;
  }
  return data?.message
    ? toast.error(data?.message, { position: "top-right" })
    : null;
};
